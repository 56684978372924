<template>
  <div class="primary-input">
    <Multiselect
      ref="select"
      class="primary-input__input"
      mode="tags"
      :searchable="true"
      :close-on-select="false"
      valueProp="id"
      trackBy="name"
      label="name"
      noResultsText="Ничего не найдено"
      noOptionsText="Нет данных"
      :can-clear="false"
      :caret="false"
      v-model="model"
      :options="items"
      @open="select?.input?.focus()"
      :style="{ paddingBottom: dropdownHeight + 'px', marginBottom: -dropdownHeight + 'px' }"
      :disabled="directLink && !!model?.length"
      @select="onSelect"
    >
      <template v-slot:tag="{ option, handleTagRemove }">
        <Chip class="primary-input__chip" @click="handleTagRemove(option, $event)">{{ option.name }}<IconX class="icon" /></Chip>
      </template>
      <template v-slot:option="{ option }">
        <component :is="directLink ? NuxtLink : 'span'" :to="directLink?.(option)">
          <QueryEntry :content="option.name" :query="select?.search" />
        </component>
      </template>
    </Multiselect>
    <div class="primary-input__label">Выбери направление</div>
    <div ref="action" v-if="actionText" class="primary-input__action">
      <BaseButton class="primary-input__action-btn" color-primary @click="emit('action')">{{ actionText }}</BaseButton>
      <button class="primary-input__action-mobile-btn" @click="emit('action')"><IconArrowRight class="icon" /></button>
    </div>
  </div>
</template>

<script setup>
import Multiselect from '@vueform/multiselect'
import { IconX, IconArrowRight } from '@tabler/icons-vue'
import NuxtLink from '#app/components/nuxt-link'

const props = defineProps({
  items: Array,
  modelValue: Array,
  actionText: String,
  directLink: Function
})
const emit = defineEmits(['update:modelValue', 'action'])

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const select = ref()
const dropdown = computed(() => select.value?.multiselect?.querySelector('.multiselect-dropdown'))
const action = ref()

const { height: dropdownHeight } = useElementSize(dropdown)
const { width: actionWidth } = useElementSize(action)
const actionOffset = computed(() => 25 + actionWidth.value + 'px')

const router = useRouter()
const onSelect = option => props.directLink && router.push(props.directLink(props.items.find(i => i.id === option)))
</script>

<style scoped lang="scss">
.primary-input {
  position: relative;

  > :deep(*) {
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }

  &__input {
    z-index: 10;
    background: var(--color-bg);
    box-shadow: 0 9px 64px rgba(0, 0, 0, 0.08);
    height: 94px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-elements-primary);
    border: none;
    box-sizing: content-box;
    @media (max-width: 920px) {
      height: 70px;
      font-size: 14px;
      line-height: 20px;
    }

    &.is-active {
      border: none;
    }
    &, &.is-open {
      border-radius: 28px;
      @media (max-width: 920px) {
        border-radius: 24px;
      }
    }

    :deep(.multiselect-wrapper) {
      cursor: text;
      height: 100%;
      padding: 11px v-bind(actionOffset) 11px 18px;
      align-items: flex-end;
      border-radius: inherit;
      overflow: hidden;
      @media (max-width: 920px) {
        padding: 11px v-bind(actionOffset) 11px 14px;
      }

      .multiselect-tags {
        margin: 0;
        padding: 0;
        flex-wrap: nowrap;
        align-items: stretch;
        min-height: 34px;
        padding-left: 18px;
        margin-left: -18px;
        width: calc(100% + 18px);
        overflow-x: auto;
        @media (max-width: 920px) {
          min-height: 32px;
          padding-left: 14px;
          margin-left: -14px;
          width: calc(100% + 14px);
        }

        &::-webkit-scrollbar { display: none; }
      }
      .multiselect-tags-search-wrapper {
        margin: 0;
        min-width: 200px;
        @media (max-width: 920px) {
          min-width: 100px;
        }
      }
      .chip + .multiselect-tags-search-wrapper {
        height: auto;
      }
      .multiselect-tags-search {
        padding: 0;
        height: 100%;
        border-radius: 0;
        background: none;
      }
    }
    :deep(.multiselect-dropdown) {
      padding: 11px 0;
      z-index: -1;
      top: 94px;
      bottom: unset;
      left: 0;
      right: 0;
      transform: none;
      //box-shadow: 0 9px 64px rgba(0, 0, 0, 0.08);
      border: none;
      border-top: 1px solid var(--color-separator);
      border-radius: 0 0 28px 28px;
      max-height: min(50vh, 200px);
      @media (max-width: 920px) {
        top: 70px;
        border-radius: 0 0 24px 24px;
      }
      &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: var(--color-elements-quantery);
      }

      .multiselect-option {
        padding: 5px 16px;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: var(--color-elements-primary);
        user-select: none;

        &.is-pointed {
          background: var(--color-primary);
          color: #fff;
        }

        &:has(> a) {
          padding: 0;
        }
        a {
          display: block;
          padding: 5px 16px;
          opacity: 1;
        }
      }
    }
  }
  &__chip {
    border-width: 1px;
    margin-right: 8px;
    gap: 4px !important;
    padding: 6px 8px;
    flex-shrink: 0;
    @media (max-width: 920px) {
      margin-right: 4px;
      gap: 0 !important;
      padding: 2px 4px;
    }
  }

  &__label {
    position: absolute;
    top: 16px;
    left: 18px;
    transform: translateY(35px - 16px);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-elements-secondary);
    pointer-events: none;
    z-index: 20;
    transition: .1s transform, .1s color, .1s font-size, .1s line-height;
    @media (max-width: 920px) {
      top: 10px;
      left: 14px;
      transform: translateY(23px - 10px);
    }
  }
  &__input:focus-within ~ &__label, &:has(.primary-input__chip) &__label {
    transform: translateY(0);
    color: var(--color-primary);
    @media (max-width: 920px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__action {
    position: absolute;
    top: 19px;
    right: 18px;
    z-index: 20;
    @media (max-width: 920px) {
      top: 17px;
      right: 14px;
    }
  }
  &__action-btn {
    @media (max-width: 920px) {
      display: none !important;
    }
  }
  &__action-mobile-btn {
    width: 36px;
    height: 36px;
    background: var(--color-primary);
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    color: var(--color-bg);
    @media (max-width: 920px) {
      display: flex;
    }

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
