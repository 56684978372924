<template>
  <div class="horiz-container">
    <SectionWithAction class="mentors-section">
      <template #title>
        Более {{ moreoverRound(data.meta.total) < 200 ? '200' : moreoverRound(data.meta.total) }}&nbsp;наставников
        готовы&nbsp;решить твою&nbsp;задачу
      </template>
      <template #action>
        <BaseButton :to="{ name: 'mentor-list' }" class="home-mentors__button" big>Подобрать ментора</BaseButton>
      </template>
      <template #default>
        <div class="home-mentors__list default-grid">
          <HomeMentorCard
            v-if="data"
            v-for="(mentor, i) in data.data"
            :mentor="mentor"
            :key="mentor.id"
            :style="sliceStyle(i)"
          />
        </div>
      </template>
    </SectionWithAction>
  </div>
</template>

<script setup>
import { searchMentors } from '@/api/mentoring'
import { sliceStyle } from '~/components/common/SlicesHover.vue'

const { data } = await useAsyncData(() => searchMentors({ main: '1', per_page: 4 }))
</script>

<style scoped lang="scss">
.mentors-section {
  padding: 48px 0;
}

.home-mentors {
  &__list {
    @media (max-width: 920px) {
      overflow-x: auto;
      display: flex;
      padding: 0 15px;
      margin-left: -15px;
      width: calc(100% + 30px);
    }
  }
}
</style>
