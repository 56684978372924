<template>
  <div class="for-what-section">
    <div class="horiz-container">
      <div class="for-what default-grid">

        <div class="for-what__item">
          <img draggable="false" src="@/assets/img/for-what/beginners-shape.svg" alt="Новичкам">
          <Chip not-clickable>Новичкам</Chip>
          <p>Прокачайся после курсов и&nbsp;найди работу</p>
          <img draggable="false" src="@/assets/img/for-what/beginners.png" alt="Новичкам">
        </div>
        <div class="for-what__item">
          <img draggable="false" src="@/assets/img/for-what/experts-shape.svg" alt="Опытным">
          <Chip not-clickable>Опытным</Chip>
          <p>Расширь свои навыки по новым&nbsp;стекам и&nbsp;направлениям</p>
          <img draggable="false" src="@/assets/img/for-what/experts.png" alt="Опытным">
        </div>
        <div class="for-what__item">
          <img draggable="false" src="@/assets/img/for-what/everyone-shape.svg" alt="Всем">
          <Chip not-clickable>Всем</Chip>
          <p>Найди быстрое решение своей нетипичной задачи</p>
          <img draggable="false" src="@/assets/img/for-what/everyone.png" alt="Всем">
        </div>
        <div class="for-what__item">
          <img draggable="false" src="@/assets/img/for-what/business-1-shape.svg" alt="Бизнесу">
          <Chip not-clickable>Бизнесу</Chip>
          <p>Развивай навыки сотрудников с&nbsp;экспертами платформы</p>
          <img draggable="false" src="@/assets/img/for-what/business-1.png" alt="Бизнесу">
        </div>
        <div class="for-what__item">
          <img draggable="false" src="@/assets/img/for-what/business-2-shape.svg" alt="Бизнесу">
          <Chip not-clickable>Бизнесу</Chip>
          <p>Внедри инструменты менторинга в&nbsp;своей компании по&nbsp;модели SaaS или White Label</p>
          <img draggable="false" src="@/assets/img/for-what/business-2.png" alt="Бизнесу">
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.for-what-section {
  padding: 24px 0;
  @media (max-width: 920px) {
    padding: 16px 0;
  }
}

.for-what {
  gap: 8px;
  grid-auto-rows: 244px;
  @media (max-width: 920px) {
    grid-auto-rows: auto;
  }

  &__item {
    position: relative;
    border-radius: 54px;
    border: 1px solid var(--color-elements-quantery);
    overflow: hidden;
    padding: 37px 29px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 13px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 920px) {
      min-height: 136px;
      padding: 19px 20px;
      font-size: 14px;
      line-height: 20px;
      gap: 8px;
      border-radius: 38px;
      grid-column: span 6 !important;
    }
    @media (max-width: 635px) {
      grid-column: span 12 !important;
    }

    :deep(.chip) {
      border-width: 1px;
      @media (max-width: 920px) {
        font-size: 10px;
        line-height: 14px;
      }
    }
    :deep(.chip), p {
      position: relative;
      z-index: 2;
    }
    p {
      max-width: 60%;
      @media (max-width: 920px) {
        width: 164px !important;
        max-width: none;
      }
    }
    img {
      position: absolute;
      user-select: none;
    }

    &:nth-child(1) {
      grid-column: span 6;
      @media (max-width: 1338px) {
        grid-column: span 4;
      }

      p {
        width: 208px;
        @media (max-width: 1338px) {
          width: 143px;
        }
      }
      img:first-child {
        width: 250px;
        right: 111px;
        bottom: -13px;
        @media (max-width: 1338px) {
          width: 192px;
          right: -24px;
          bottom: 52px;
        }
        @media (max-width: 920px) {
          width: 129px;
          right: 14px;
          bottom: 9px;
        }
      }
      img:last-child {
        width: 241px;
        right: 9px;
        bottom: -34px;
        @media (max-width: 1338px) {
          width: 154px;
          right: -1px;
          bottom: -17px;
        }
        @media (max-width: 920px) {
          width: 124px;
          right: -23px;
          bottom: -20px;
        }
      }
    }
    &:nth-child(2) {
      grid-column: span 3;
      @media (max-width: 1338px) {
        grid-column: span 4;
      }

      p {
        width: 150px;
      }
      img:first-child {
        width: 199px;
        right: -56px;
        bottom: 115px;
        @media (max-width: 920px) {
          width: 112px;
          right: -6px;
          bottom: 52px;
        }
      }
      img:last-child {
        width: 141px;
        right: 0;
        bottom: -15px;
        @media (max-width: 920px) {
          width: 105px;
          right: -4px;
          bottom: -19px;
        }
      }
    }
    &:nth-child(3) {
      grid-column: span 3;
      @media (max-width: 1338px) {
        grid-column: span 4;
      }
      @media (max-width: 920px) {
        grid-column: span 12 !important;
      }

      p {
        width: 143px;
      }
      img:first-child {
        width: 147px;
        right: 13px;
        bottom: 88px;
        @media (max-width: 920px) {
          width: 78px;
          right: 5px;
          bottom: 48px;
        }
      }
      img:last-child {
        width: 159px;
        right: -11px;
        bottom: -16px;
        @media (max-width: 920px) {
          width: 117px;
          right: 5px;
          bottom: -11px;
        }
      }
    }
    &:nth-child(4) {
      grid-column: span 5;
      @media (max-width: 1160px) {
        grid-column: span 6;
      }

      p {
        width: 216px;
        @media (max-width: 920px) {
          width: 184px !important;
        }
      }
      img:first-child {
        width: 114px;
        right: -5px;
        bottom: 41px;
        @media (max-width: 920px) {
          width: 68px;
          right: -5px;
          bottom: 19px;
        }
      }
      img:last-child {
        width: 232px;
        right: 8px;
        bottom: 0;
        @media (max-width: 920px) {
          width: 129px;
          right: -15px;
        }
      }
    }
    &:nth-child(5) {
      grid-column: span 7;
      @media (max-width: 1160px) {
        grid-column: span 6;
      }

      p {
        width: 342px;
      }
      img:first-child {
        width: 199px;
        right: 1px;
        bottom: 76px;
        @media (max-width: 920px) {
          width: 142px;
          right: 15px;
          bottom: 50px;
        }
      }
      img:last-child {
        width: 234px;
        right: 17px;
        bottom: -2px;
        @media (max-width: 920px) {
          width: 140px;
          right: -20px;
        }
      }
    }
  }
}
</style>
