<template>
  <article class="home-mentor-card">
    <NuxtLink :to="{ name: 'mentor-profile', params: { id: mentor.id } }" target="_blank" class="home-mentor-card__image-link">
      <SlicesHover class="home-mentor-card__image-container">
        <img :src="mentor.avatar_url" v-if="mentor.avatar_url" alt="Фото" class="home-mentor-card__image">
        <AvatarPlaceholder :data="mentor" v-else class="home-mentor-card__image" />
      </SlicesHover>
    </NuxtLink>
    <SkillChips :skills="mentor.skills" :display-count="2" class="home-mentor-card__skills" />
    <div class="home-mentor-card__info">
      <h3 class="home-mentor-card__name">{{ fullName }}</h3>
      <div class="home-mentor-card__desc" v-if="mentor.personal?.about">{{ mentor.personal?.about }}</div>
      <LinkButton :to="{ name: 'mentor-profile', params: { id: mentor.id } }" target="_blank">Подробнее<IconArrowRight class="icon" /></LinkButton>
    </div>
  </article>
</template>

<script setup>
import AvatarPlaceholder from '@/components/common/AvatarPlaceholder'
import SkillChips from '@/components/common/SkillChips'
import LinkButton from '@/components/common/LinkButton'
import { IconArrowRight } from '@tabler/icons-vue'

const props = defineProps(['mentor'])

const fullName = computed(() => `${props.mentor.first_name} ${props.mentor.last_name}`)
</script>

<style scoped lang="scss">
.home-mentor-card {
  grid-column: span 3;
  @media (max-width: 920px) {
    width: 237px;
    flex-shrink: 0;
    padding: 15px 0;
  }
  &__image-link {
    opacity: 1 !important;
  }
  &__image-container {
    height: 315px;
    border-radius: 16px;
    @media (max-width: 920px) {
      height: 265px;
    }
  }
  &__skills {
    margin-top: 24px;
    :deep(.chip) {
      border-width: 1px;
    }
  }
  &__info {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__name {
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
  }
  &__desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-elements-primary);
  }
}
</style>
