<template>
  <div class="skills-section">
    <div class="horiz-container">
      <div class="chips">
        <Chip v-for="skill in skills.data" :key="skill.id" :title="skill.name" :to="{ name: 'mentor-list', params: { slug: skill.slug } }">{{ skill.name }}</Chip>
        <Chip :to="{ name: 'mentor-list' }"><span>И еще <b>{{ moreoverRound(skills.total) }}+</b> направлений</span></Chip>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getSkills } from '~/api/skills'
import Chip from '~/components/common/Chip.vue'

const { data: skills } = await useAsyncData(() => getSkills(true))
</script>

<style scoped lang="scss">
.chips {
  display: flex;
  gap: 12px;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    display: none;
  }
  & > * {
    border-width: 1px;
    flex-grow: 1;
    flex-shrink: 0;
    &:last-child {
      flex-grow: 0;
      @media (max-width: 920px) {
        flex-grow: 1;
      }
    }
  }
  @media (max-width: 920px) {
    gap: 6px;
  }
}
</style>
