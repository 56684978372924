<template>
  <section class="hero-section">
    <div class="horiz-container">
      <div class="hero">
        <div class="hero__header default-grid">
          <h1 class="hero__title">Прокачивай навыки <br>с&nbsp;проверенными менторами и&nbsp;участвуй в&nbsp;коммерческих проектах</h1>
          <p class="hero__desc">Учись, <b>бесплатно откликайся</b> в&nbsp;проекты на&nbsp;платформе и&nbsp;получи оффер
            на&nbsp;35% быстрее и&nbsp;<b>выгоднее, чем на&nbsp;фриланс-биржах</b> за&nbsp;счет возможности отслеживания прогресса твоего&nbsp;профиля</p>
          <div class="hero__feature-list">
            <div class="hero__feature">
              <IconDeviceLaptop class="icon" />
              <span>Менторы по {{ skills.length }} <br>{{ declOfNum(skills.total, ['направлению', 'направлениям', 'направлениям']) }}</span>
            </div>
            <div class="hero__feature">
              <IconUserCheck class="icon" />
              <span>Менторы проверены <br>модерацией</span>
            </div>
            <div class="hero__feature">
              <IconShield class="icon" />
              <span>Оплата после <br>занятия</span>
            </div>
          </div>
        </div>
        <div class="hero__main">
          <PrimaryTagsInput
            :items="skills"
            v-model="selected"
            :direct-link="directLink"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { IconDeviceLaptop, IconUserCheck, IconShield } from '@tabler/icons-vue'
import { getMentorsMeta } from '~/api/mentoring'

const router = useRouter()

const { data: skills } = await useAsyncData(getMentorsMeta, { transform: d => d.skills })

const selected = ref([])
const go = () => router.push({
  name: 'mentor-list',
  query: selected.value.length ? { 'skills[]': selected.value } : {}
})

const directLink = skill => ({
  name: 'mentor-list',
  ...(skill.slug
  ? { params: { slug: skill.slug } }
  : { query: { 'skills[]': skill.id } })
})
</script>

<style scoped lang="scss">
.hero-section {
  padding: 24px 0;
  @media (max-width: 920px) {
    padding: 16px 0;
  }
}

.hero {
  background: var(--color-bg-secondary);
  border-radius: 40px;
  padding: 24px;
  color: var(--color-elements-primary);
  @media (max-width: 920px) {
    border-radius: 32px;
    padding: 24px 15px 20px;
  }

  &__header {
    gap: 24px;
    @media (max-width: 920px) {
      gap: 20px;
    }
  }
  &__title {
    grid-column: span 7;
    font-weight: 900;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 1px;
    text-transform: uppercase;
    @media (max-width: 920px) {
      grid-column: span 12;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0;
      br { display: none; }
    }
  }
  &__desc {
    grid-column: 9 / 13;
    grid-row: span 2;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    @media (max-width: 920px) {
      grid-column: span 12;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    b {
      font-weight: inherit;
      color: var(--color-primary);
      @media (max-width: 920px) {
        font-weight: 700;
        color: inherit;
      }
    }
  }
  &__feature-list {
    grid-column: span 7;
    display: flex;
    gap: 32px;
    align-items: flex-start;
    @media (max-width: 920px) {
      grid-column: span 12;
      gap: 11px;
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__feature {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    gap: 15px;
    align-items: flex-start;
    @media (max-width: 920px) {
      align-items: center;
      br { display: none; }
    }

    .icon {
      color: var(--color-elements-tertiary);
      flex-shrink: 0;
    }
  }

  &__main {
    margin-top: 43px;
    @media (max-width: 920px) {
      margin-top: 20px;
    }
  }
}
</style>
